<template>
  <div>
    <header class="clearfix">
      <div class="logo-owner">
        <a style="color: #ffffff" href="javascript:void(0)" @click="redirect('dashboard')">DISH <span> Portal</span></a>
      </div>
      
      <div class="page-search">
        <div class="search-icons-head"><i class="fa fa-search"></i></div>
        <vue-autosuggest
        v-model="query"
        :suggestions="filteredOptions"
        @focus="focusMe"
        @click="clickHandler"
        @input="onInputChange"
        @selected="onSelected"
        limit= "5"
        :get-suggestion-value="getSuggestionValue"
        :input-props="{id:'autosuggest__input', placeholder:'Search for...'}">
        <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
          
          <div style="{ display: 'flex', color: 'navyblue'}">{{suggestion.item.name}}</div>
        </div>
      </vue-autosuggest>
      </div>
      <div class="right-user-ul">
        <div class="media">
          <div class="media-left">
            <div class="user-dash-pros image-upload" v-bind:style="{ backgroundImage: 'url(' + image + ')' }">
            </div>
            
          </div>
          <div class="media-body">
            <h4 class="media-heading">
              {{ User.email }} <i class="fa fa-angle-down"></i>
            </h4>
           
          </div>
        </div>
        <div class="pro-drop-downhead">
          <h6>Welcome, <span>{{ User.email }}</span></h6>
        

          <ul class="clearfix">
            <li class="m-hinters" @click="HNTopen()">
              HNT Conversion Price: <span>$ {{ oracleprice.data ? (oracleprice.data.price / 100000000).toFixed(2) : '' }}</span>
            </li>
         
            <li>
              <a href="javascript:void(0)" @click="redirect('profile')"><i class="hrms-profile"></i> My profile</a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="redirect('change-password')"><i class="hrms-password"></i> Change password</a>
            </li>
            <li>
              <a href="javascript:void(0)" @click.prevent="signOut"><i class="hrms-logout-1"></i> Log out</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="not-list-a">
        <ul class="clearfix">
          <li class="hinters" @click="HNTopen()">
            <span>HNT: $ {{ hntPrice ? (hntPrice) : 'N/A' }}</span>
            <span> | </span>
            <span>Mobile: {{hmtPrice ? `$ ${hmtPrice.toFixed(6)}` : 'N/A'}}</span>
            <span> | </span>
            <span>IOT: {{iotPrice ? `$ ${iotPrice.toFixed(6)}` : 'N/A'}}</span>
          </li>
          <li class="notify-me" @click="toggleNotification">
            <a
              href="javascript:void(0)"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Notifications"
              
              ><i class="fas fa-bell"></i>
              <div class="notify">
                <span v-if="notificationsCnt>0" class="heartbit"></span>
                <span class="point" id="wishlist_num">{{notificationsCnt}}</span>
              </div></a
            >
          </li>
        </ul>
      </div>
      <div
        id="LeasedHotspots"
        class="links-ab red-display"
        data-toggle="tooltip"
        data-placement="bottom"
        data-original-title="Leased Hotspots"
        @click="toggleLeasedHotspot"
      >
        <i class="fas fa-wifi"></i>
      </div>
    </header>

    <div class="right-link-panel notification-panel mCustomScrollbar">
      <h4><i class="fas fa-bell"></i> Notifications</h4>
      <div class="table-responsive">
        <table class="table">
          <tbody>
            <tr v-for="item in notifications" :key="item.ID">
              <td>{{item.message}}</td>
              <td @click="updateNotificationStatus(item.ID)" class="close-noti"><i class="fas fa-close"></i> </td>
            </tr>
            <tr v-if="!notificationsCnt"><td>No new notifications.</td></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
input {
  width: 260px;
  padding: 0.5rem;
}
 
#autosuggest ul {
  width: 100%;
  color: rgba(30, 39, 46,1.0);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 .5rem 0;
      background: #fff;
    border-radius: 10px;
    width: 100%;
    min-height: 30px;
    max-height: 300px;
    overflow: auto;

}
#autosuggest ul li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
    background: #fff;
  
}
#autosuggest ul li:hover {
  cursor: pointer;
}
 
.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
  
}


.autosuggest__results-container{
      position: absolute;
    width: 100%;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
      background: #fff;
}
 
#autosuggest { width: 100%; display: block;
position: relative;
}
.autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178,0.2);
}
</style>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import firebase from "firebase/app";
  import { API_BASE_URL, API_HELIUM_URL, API_JAG_HELIUM_URL, HOTSPOTTY_BASE_URL, HOTSPOTTY_TOKEN } from '../../../constants';
  import './index.css';

  export default {
      name: 'App',
      data: () => ({
        query: "",
      selected: "",
      suggestions: [
        {
          data: [
          ]
        }
      ],
        User:'',
        oracleprice: {
          data: ''
        },
        notifications: [],
        notificationsCnt: 0,
        image:null,
        hmtPrice: '',
        iotPrice: '',
        hntPrice: ''
      }),

      computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
          user: "user",
        }),
        filteredOptions() {
      return [
        { 
          data: this.suggestions[0].data.filter(option => {
            return option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
          })
        }
      ];
    }

      },
      mounted() {

        //get user from local storage
        this.User = JSON.parse(localStorage.getItem('User'));

        this.uid = this.User.uid;
        this.getOracleValue();
        this.getCurrUserNotifications();
        
        this.getProfileImage();
        this.getHotspots();
        this.getPrice();
       
      },
      methods: {

        clickHandler(item) {
      // event fired when clicking on the input
    },
    getPrice(){
      axios
          .get(`${HOTSPOTTY_BASE_URL}/tokens/prices`, {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
            },
          })
          .then((resp) => {
            this.hmtPrice = resp.data.data.mobile.usd;
            this.iotPrice = resp.data.data.iot.usd;
            this.hntPrice = resp.data.data.hnt.usd
          }).catch((err) => {
            console.log(err);
          });
    },
    onSelected(item) {
      this.selected = item.item;
      localStorage.setItem('searched_hotspot', this.selected.id)
     let segment =  (new URL(window.location.href)).pathname.split('/')[1];

if(segment=="hotspot")
{
  this.$router.go();
}else{
  
  this.$router.push("hotspot");
}
      
      
    },
    onInputChange(text) {
      // event fired when the input changes
      console.log(text)
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    focusMe(e) {
      console.log(e) // FocusEvent
    },
 
          redirect(link) { //custom redirect method
              this.$router.push(link);
          },
          HNTopen() {
            window.open("https://coinmarketcap.com/currencies/helium/", "_blank");
          },
          getOracleValue() {
            axios
              .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
                headers: {
                  accept: "application/json",
                },
              })
              .then((resp) => {
                this.oracleprice = resp.data;
              }).catch((err) => {
                console.log(err);
              });
          },
          formatHotspotName(name){
      let newName = name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
          return letter.toUpperCase();
      });

      return newName;
    },
          getHotspots() {
            axios
              .get(`${API_JAG_HELIUM_URL}/v1/user/hotspots/${this.uid}`, {
                headers: {
                  accept: "application/json",
                },
              })
              .then((resp) => {
                
                console.log(resp)

                   let hotspots = resp.data;
               
        if(hotspots.length > 0) {
          hotspots.forEach((element, index) => {
            let hotspotObj = {};
           
            hotspotObj.name = this.formatHotspotName(element.hotspot_name);
         
            hotspotObj.id = (element.Haddress) ? element.Haddress : 'N/A';
           
            this.suggestions[0].data.push(hotspotObj);
          });
        }

              }).catch((err) => {
                console.log(err);
              });
          },
          signOut() {
            firebase
              .auth()
              .signOut()
              .then(() => {

                //remove from local storage
                localStorage.removeItem('User');
                localStorage.removeItem('activeHotspot');
                localStorage.removeItem('reloaded');

                this.$cookies.remove("uid");
                this.$cookies.remove("hotspotStatus");
                this.$router.replace({
                  name: "login",
                });
              });
          },
          toggleLeasedHotspot(event){ //this will toggle class for Leased Hotspot side bar
            /*
            if (document.body.classList.contains('right-link-open')) {
                document.querySelector("body").className = "landscape";
            }else{
              document.querySelector("body").className = "right-link-open";
            }*/
            
          },
          toggleNotification(event){ //this will toggle class for notification side bar

            if (document.body.classList.contains('right-notify-open')) {
                document.querySelector("body").className = "landscape";
            }else{
              document.querySelector("body").className = "landscape right-notify-open";
            }
            
          },
          getCurrUserNotifications(){ //get current user all notofications
            

              axios
              .get(`${API_BASE_URL}/user/notification/${this.uid}`, {
                headers: {
                  accept: "application/json",
                },
              })
              .then((resp) => {

               
                if(resp.data){

                  this.notifications = resp.data;
                  this.notificationsCnt = resp.data.length;

                }
               
                
              }).catch((err) => {
                 console.log(err);
              });
          },
          updateNotificationStatus(id){ //get current user all notofications
            
              let status = 0;

              this.updateStatus(id,status)
              .then(data => {
                  console.log("status updated...",data)
                  //get all upadetd notification
                  this.getCurrUserNotifications();
              })
              .catch(err => console.log(err))
              
          },
          async updateStatus(id,status) {

            let payload = {
               notificationId:id,
               status:status
            };

            // create a promise for the axios request
            const response = await axios.put(`${API_BASE_URL}/user/updateNotificationStatus/${this.uid}`, payload,{ headers: { "Content-Type": "application/json" }});

            // return it
            return response;
          },
          getProfileImage(){
            axios.get(`${API_BASE_URL}/user/info/${this.uid}`,{
                headers: { accept: "application/json" },
              })
              .then((resp) => {
                   
                  this.image = API_BASE_URL+resp.data[0]['profile_image'];
                  if(resp.data[0].profile_image){
                    this.image = API_BASE_URL + resp.data[0].profile_image
                  }else{
                    this.image = 'img/test-profile.26f15de2.jpg';
                  }
                 console.log("this.image = ",this.image)

              });

          }
      },
  };
</script>