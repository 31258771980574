<template>
  <v-app>
    <Header></Header>
    <Sidebar></Sidebar>
     <v-main>
      <router-view></router-view>
     </v-main>
  </v-app>
</template>



<script>
import Header from './header';
import Sidebar from './sidebar';
export default {
  components: {
    Header,
    Sidebar
  }
}


</script>