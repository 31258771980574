<template>
  <div class="main-view-sec">
    <Loader v-if="$root.isLoading"></Loader>
    <div class="page-headings">
      <h1 class="clearfix">Rewards
        <span><a href="javascript:void(0)" @click="redirect('dashboard')">Home</a> <a href="javascript:void(0)">Rewards</a> 
          <button class="reqcash-btns btn" type="button" @click="requestPaymentButton?newRequest():null">
            Request Cashback 
          </button>
        </span>
      </h1>
    </div>
    <div class="dash-chart-outer">
      <div class="dash-counts">
        <div class="row">
          <div class="col-sm-4">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="fa fa-check"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{ totalHNTMined }} HNT | ${{totalHNTMinedDollar}}
                  </h4>
                  <p>Total HNT Mined </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="fa fa-money-bill"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{remainHNTWithdrawal}} HNT | ${{remainHNTWithdrawalDollar}}
                  </h4>
                  <p>Remain HNT to Withdrawal </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="fa fa-star"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{ pendingHNT }} HNT | ${{pendingHNTDollar}}
                  </h4>
                  <p>Pending Approval</p>
                </div>
                <span class="vertical-line"></span>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{ totalHNTPaid }} HNT | ${{totalHNTPaidDollar}}
                  </h4>
                  <p>Total HNT Paid Out</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-3">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="hrms-page"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">4.79 HNT - $195.04 </h4>
                  <p>Remain HNT to withdrawal </p>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="dash-counts">
        <div class="row">
          <div class="col-sm-4">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="fa fa-check"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{ totalHMTMined }} Mobile | ${{ totalHMTMinedDollar  }}
                  </h4>
                  <p>Total Mobile Mined </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="fa fa-money-bill"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{remainHMTWithdrawal}} Mobile | ${{ remainHMTWithdrawalDollar }}
                  </h4>
                  <p>Remain Mobile to Withdrawal </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="fa fa-star"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{ pendingHMT }} Mobile | ${{ pendingHMTDollar  }}
                  </h4>
                  <p>Pending Approval</p>
                </div>
                <span class="vertical-line"></span>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{ totalHMTPaid }} Mobile | ${{ totalHMTPaidDollar }}
                  </h4>
                  <p>Total Mobile Paid Out</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dash-counts">
        <div class="row">
          <div class="col-sm-4">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="fa fa-check"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{ totalIOTMined }} IOT | ${{ totalIOTMinedDollar }}
                  </h4>
                  <p>Total IOT Mined </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="fa fa-money-bill"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{remainIOTWithdrawal}} IOT | ${{ remainIOTWithdrawalDollar }}
                  </h4>
                  <p>Remain IOT to Withdrawal </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card-style m-b-30">
              <div class="media">
                <div class="media-left"><i class="fa fa-star"></i></div>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{ pendingIOT }} IOT | ${{ pendingIOTDollar }}
                  </h4>
                  <p>Pending Approval</p>
                </div>
                <span class="vertical-line"></span>
                <div class="media-body">
                  <h4 class="media-heading">
                    {{ totalIOTPaid }} IOT | ${{ totalIOTPaidDollar }}
                  </h4>
                  <p>Total IOT Paid Out</p>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card-style m-b-30" data-scroll-index="0">
            <div class="box-head clearfix">Transfer activity
              <div class="pull-right">
                <div class="form-group serch-hotspot">
                   <v-text-field
                    v-model="search"
                    @keyup="getRequest();"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="all-table-theme">
              <div class="table-responsive">
                 <v-data-table
                    :headers="headers"
                    :items="requestLog"
                    
                    :footer-props="{'itemsPerPageOptions':[5, 10, 15]}"
                    item-key="name"
                    class="table table-hover"
                    
                  >
                    <template v-slot:[`item.payment`]="{ item }">
                      <span>{{ item.payment == 'HMT' ? 'Mobile' : item.payment }}</span>
                    </template>
                    <!-- <template v-slot:[`item.entered`]="{ item }">
                      <span>{{ item.entered }}</span>
                    </template>
                    <template v-slot:[`item.HNTchange`]="{ item }">
                      <span>{{ item.HNTchange }}</span>
                    </template> -->
                  </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="twofactor" persistent width="500">
      <v-card>
        <v-card-title class="headline purple darken-1 white--text">
          Two Factor Autentication is Needed
        </v-card-title>
        
        <v-card-text class="pa-3">
          <div>
            <span v-if="twofa">
              Scan this QR code with Google Autenticator and do not
              delete the app. If you remove that you will not be able to
              restore your code. Once you already have the Google
              Authenticator code. Then enter the code provided from the
              app.
            </span>
              <span v-if="!twofa">
              Please enter the sign-in 2FA code from your authenticator app.  
              If having issues, then reach out to <strong>support@jagindustrials.com</strong>.
            </span>
              <v-row v-if="twofa" align="center" justify="center">
                <template>
                  <figure>
                    <vue-qrcode
                      :value="this.authkey"
                      :options="{
                        errorCorrectionLevel: 'Q',
                        width: 200,
                        height: 200,
                      }"
                    ></vue-qrcode>
                  </figure>
                </template>
                <v-btn icon color="green" @click="getNewQR()">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </v-row>
              <v-row align="center" justify="center">
                <span>
                  Please enter the OTP here to check your settings
                </span></v-row
              >
              <v-row align="center" justify="center">
                <CodeInput
                  :key="componentKey"
                  :loading="false"
                  :autoFocus="true"
                  class="input"
                  v-on:change="onChange"
                  v-on:complete="onComplete"
                />
              </v-row>
              <br /><br /><v-alert type="success" v-model="correcttoken"
                >  {{ dynamicMessage.generated_token_correct }}</v-alert
              >
              <v-alert type="error" v-model="errortoken"
                >{{ dynamicMessage.generated_token_incorrect }}</v-alert
              ><br />
              <v-alert type="error" v-model="online"
                >Please check your internet connection and try again.</v-alert
              ><br />
              <!--<v-row align="end" justify="end">
                <v-btn color="success" @click="SaveToken()"
                  >SAVE</v-btn
                >
                </v-row>
              -->
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" persistent width="500">

      <v-card>
        <v-card-title class="headline purple darken-1 white--text">
          Request Cashback
        </v-card-title>
        <v-card-text class="pa-3">
          <div>
            <span v-if="message == 'Zelle'">
              Please send an email to
              <a href="mailto: payments@jagindustrials.com"
                >payments@jagindustrials.com</a
              >
              with subject title “Payment Request - your name” from the
              email associated with this account. In your email, list
              the amount you wish to withdraw with your full legal name
              and payment method (HNT wallet, BTC wallet, ETH wallet,
              Paypal, Venmo or Zelle). Please ensure your designated
              address is correct in your profile prior to requesting a
              cash out. Note payments may take a few days to process.
              <br>
              A minimum of 2 HNT is needed to cash out.  If you are closing your account, and returning your hotspot, then please reach out to  <a href="mailto: payments@jagindustrials.com">payments@jagindustrials.com</a>.
            </span>

            <span v-if="message != 'Zelle' && message != 'Venmo'">
              Please ensure your designated address is correct in your
              profile prior to requesting a cash out. JAG is not
              responsible for incorrect wallet addresses. Click
              <router-link :to="'/profile'" class="menu-item"
                >here</router-link
              >
              to validate. Note payments may take a few days to process.<br>
              A minimum of 2 HNT is needed to cash out.  If you are closing your account, and returning your hotspot, then please reach out to <a href="mailto: payments@jagindustrials.com">payments@jagindustrials.com</a>.
            </span>

            <v-container style="width:100% !important">
              <v-row v-if="alert" style="margin-top: 24px">
                <v-alert type="error" transition="fade-transition">
                  {{ errorMsg }}
                </v-alert>
              </v-row>
              <v-row v-if="sucessAlert" style="margin-top: 24px">
                <v-alert type="success" transition="fade-transition">
                  {{ dynamicMessage.thankyou_submitting_request }}
                </v-alert>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="email"
                  label="Email"
                  type="text"
                  readonly
                  disabled
                ></v-text-field>
              </v-row>
              <v-row v-if="message != 'Zelle'">
                <v-text-field
                  v-model="amount"
                  :label="dynamicLabel"
                  type="text"
                  min="0"
                  step="0.01"
                  :max="remainbalance"
                  @keypress="onlyForCurrency"
                  @keyup="validateAmount"
                ></v-text-field>
              </v-row>
              <v-row>
                <span v-if="message == 'HNT'" class="gold_4--text">Available To Withdraw {{ remainHNTWithdrawal }} HNT</span>
                <span v-if="message == 'HMT'" class="gold_4--text">Available To Withdraw {{ remainHMTWithdrawal }} HMT</span>
                <span v-if="message == 'IOT'" class="gold_4--text">Available To Withdraw {{ remainIOTWithdrawal }} IOT</span>
              </v-row>
              <v-row>
                <v-select
                  :items="items"
                  label="Payment method"
                  v-model="message"
                  @change="changeAlert()"
                ></v-select>
              </v-row>
              <v-row v-if="message != 'Zelle'">
                 <span v-if="!isHNTAddress || !isHMTAddress || !isIOTAddress" style="color:red; font-size:13px;">
                  Please enter the valid 51  HNT, IOT and HMT Wallet Address! 
                  <b><a style="color:#184cf8" href="javascript:void(0)" @click="redirect2('profile')"><i class="hrms-profile"></i> My profile->Edit Profile</a></b>
                </span>
                <div>
                  <v-btn color="success" :disabled="!isHNTAddress || !isHMTAddress || !isIOTAddress" @click="sendRequest()">Submit</v-btn>
                  <v-btn color="danger" @click="closeRequest()">Close</v-btn>
                </div>
              </v-row>
              
            </v-container>
          </div>
        </v-card-text>
        
      </v-card>
    </v-dialog>


    <!-- Email 2fa modal -->
    <v-dialog persistent width="500" v-model="isEmail2fa">
      <v-card>

        <v-card-title class="headline purple darken-1 white--text">
          Two Factor Autentication is Needed
        </v-card-title>

        <!-- 2fa using email -->
        <v-card-text class="pa-3 2fa_email_code_email">
          <div>
            <span>
              Please check your email for Two-factor authentication..
            </span>
            <div>
              <div align="center" justify="center">
                <template>
                   <v-text-field
                      v-model="form['2fa_email_code']"
                      label="2FA"
                      type="text"
                    ></v-text-field>
                </template>
              </div>

              <div>
                <v-btn color="success" @click="twofactorSubmit()">Submit 
                   <v-progress-circular
                      v-if="loading"
                      :size="20"
                      :width="2"
                      color="white"
                      indeterminate
                    ></v-progress-circular>
                </v-btn>
              </div>
              

              <div v-if="twofactorError" align="center" justify="center" style="margin-top: 24px">
                <v-alert type="error" transition="fade-transition">
                  {{twofactorErrorMsg}}
                </v-alert>
              </div>
              <div v-if="online" align="center" justify="center" style="margin-top: 24px">
                <v-alert type="error">
                   Please check your internet connection and try again.
                </v-alert>
              </div>

            </div>
          </div>
        </v-card-text>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { API_BASE_URL, API_HELIUM_URL, API_JAG_HELIUM_URL, API_HELIUM_HMT_URL, HOTSPOTTY_BASE_URL, HOTSPOTTY_TOKEN } from "../../constants";
import moment from "moment";
import Loader from '../../components/loader';
import "./style.css";

export default {
  components: { Loader },
  setup() {},
  data() {
    return {
      loader:true,
      form: {
          "2fa_email_code":  ""
      },
      // items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      items: ["HNT", "IOT", "HMT"],
      message: "HNT",
      amount: 0,
      twofactor: false,
      dialog: false,
      dialog2: false,
      hotspotDetails: [],
      hotspotTotal: [],
      errortoken: false,
      url: "",
      authkey: "",
      mapurl: "",
      remainbalance: 0,
      remainBalanceConversion: "0.00",
      twofa: false,
      start: "",
      hname: "",
      correcttoken: false,
      search: "",
      pendingAmount: 0,
      pendingAmountConversion: "0.00",
      alreadypayed: 0,
      alreadyPayedConversion: "0.00",
      commission: 0,
      signneed: false,
      first30days: 0,
      arr: [],
      today: "",
      uid: this.$cookies.get("uid"),
      oracleprice: {
        data: "",
      },
      installation: "",
      requestLog: [],
      maxearn: 0,
      authcomplete: null,
      email: "",
      userInfo: {},
      alert: false,
      sucessAlert: false,
      errorMsg: "",
      componentKey: 0,
      attrs: "",
      on: "",
      lastLastPaymentDate: "",
      requestPaymentButton: true, 
      online:false,
      pendingTransactionsTotal: 0,
       dynamicMessage: {
        no_data: "",
        no_matching_records: "",
        generated_token_incorrect: "",
        thankyou_submitting_request: "",
        hnt_amount_greater_than: "",
        insufficient_amount_hnt: "",
        selected_address_not_available: "",
      },
      investor: 0,
      isEmail2fa: false,
      twofactorError: false,
      twofactorErrorMsg: '',
      loading:false,
      isHNTAddress: true,
      isHMTAddress: true,
      isIOTAddress: true,
      remainingHNT: 0,
      remainingHNTConversion: '0.00',
      remainingHMT: 0,
      remainingIOT: 0,
      totalRequests: 0,
      options: {},
      remainHMTWithdrawal: 0,
      totalHMTPaidOut: 0,
      hotspotAll: [],

      totalHNTMined: 0,
      totalHNTMinedDollar: 0,
      remainHNTWithdrawal: 0,
      remainHNTWithdrawalDollar: 0,
      pendingHNT: 0,
      pendingHNTDollar: 0,
      totalHNTPaid: 0,
      totalHNTPaidDollar: 0,

      totalIOTMined: 0,
      totalIOTMinedDollar: 0,
      remainIOTWithdrawal: 0,
      remainIOTWithdrawalDollar: 0,
      pendingIOT: 0,
      pendingIOTDollar: 0,
      totalIOTPaid: 0,
      totalIOTPaidDollar: 0,

      totalHMTMinedWithoutAbbr: 0,
      remainHMTWithdrawalWithoutAbbr: 0,
      totalHMTMined: 0,
      totalHMTMinedDollar: 0,
      remainHMTWithdrawal: 0,
      remainHMTWithdrawalDollar: 0,
      pendingHMT: 0,
      pendingHMTDollar: 0,
      totalHMTPaid: 0,
      totalHMTPaidDollar: 0,

      hntPrice: 0,
      hmtPrice: 0,
      iotPrice: 0,

      dynamicLabel: 'Enter HNT amount'
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    headers() {
      return [
        //{ text: "Amount", value: "amount" },
        //{ text: "Currency From", value: "HNTchange" },
        { text: "Amount", value: "amount" },
        { text: "Currency To", value: "payment", sortable: false, },
        {
          text: "Status",
          value: "flag",
          sortable: false,
        },
        {
          text: "Date Completed",
          align: "start",
          value: "entered"
        },
      ];
    },
  },
  watch: {
    // alreadypayed(amt) {
    //   this.alreadyPayedConversion = this.hntToDollarConversion(amt);
    //   this.calculateRemainingHNT();
    // },
    // pendingAmount(amt) {
    //   this.pendingAmountConversion = this.hntToDollarConversion(amt);
    //   this.calculateRemainingHNT();
    // },
    // remainbalance(amt) {
    //   this.remainBalanceConversion = this.hntToDollarConversion(amt);
    //   this.calculateRemainingHNT();
    // },
    // options: {
    //   handler () {
    //     this.getRequest();
    //   },
    //   deep: true,
    // },
  },
  beforeMount() {
    this.$root.isLoading = true;
    this.getOracleValue();
    this.getglobal();
    this.getRequestOld();
    this.getUserInfo();
    this.getPrice();
    let authUser = localStorage.getItem("user");
    authUser = JSON.parse(authUser);
    if(authUser){
      this.email = authUser.email;
    }
  },
  mounted() {
    this.getDynamicMessages();
  },
  methods: {
    redirect2(link) { 
      //custom redirect method
      this.$router.push(link);
    },
    getPrice(){
      axios
          .get(`${HOTSPOTTY_BASE_URL}/tokens/prices`, {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
            },
          })
          .then((resp) => {
            this.hmtPrice = resp.data.data.mobile.usd;
            this.iotPrice = resp.data.data.iot.usd;
            this.hntPrice = resp.data.data.hnt.usd
          }).catch((err) => {
            console.log(err);
          });
    },
    changeAlert (){
      if(this.message == 'HNT'){
        this.dynamicLabel = 'Enter HNT amount'
      }
      else if(this.message == 'HMT'){
        this.dynamicLabel = 'Enter HMT amount'
      }
      else if(this.message == 'IOT'){
        this.dynamicLabel = 'Enter IOT amount'
      }
    },
    onlyForCurrency ($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.price.indexOf('.') != -1)) { // 46 is dot
        $event.preventDefault();
      }
      // restrict to 2 decimal places
      if(this.amount!=null && this.amount.indexOf(".")>-1 && (this.amount.split('.')[1].length > 1)){
      $event.preventDefault();
      }
    },
    validateAmount($event){
      setTimeout(() => {
        if (this.amount < 2){
            this.amount = '';
          }
      }, 300)
    },
    forceRerender() {
      this.componentKey += 1;
    },
    redirect(link) { 
      //custom redirect method
      if(this.investor == 1){
        this.$router.push('investor');
      }
      else{
        this.$router.push('dashboard');
      }
    },
    getNewQR() {
      //window.location.reload();
      this.generateQrCode();
    },
    onChange(v) {
      this.correcttoken = false;
      this.errortoken = false;
    },
    onComplete(v) {
      if(window.navigator.onLine){
        this.online = false;
        var data = {
          token: v,
        };
        this.$root.isLoading = true;
        axios.post(`${API_BASE_URL}/user/token/${this.uid}`, data, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          console.log(resp, data);
          this.errortoken = false;
          this.forceRerender();
          if (resp.data == true) {
            this.correcttoken = true;
            this.twofactor = false;
            this.dialog2 = true;
            this.correcttoken = false;
          } else {
            this.errortoken = true;
          }
          this.$root.isLoading = false;
        })
        .catch((resp) => {
          console.log("resprr = ",resp)
          this.$root.isLoading = false;
        });
      }
      else {
        this.online = true;
        this.forceRerender();
        setTimeout(()=>{this.twofactor = false;this.online = false;},5000)
        this.$root.isLoading = false;
      }
    },
    newRequestDedug() {
      this.dialog2 = true;
    },
    newRequest() {
      this.$root.isLoading = true;
        setTimeout(() => {
        this.$root.isLoading = false;
      }, 3000);
      axios.get(`${API_BASE_URL}/user/info/${this.uid}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {
        try {
          this.email = resp.data[0].email;
          console.log('resp.data[0]["2fa"]', resp.data[0]["2fa"]);
          if(resp.data[0]['2fa_type'] == 1) {
            this.isEmail2fa = true;
            axios.get(`${API_BASE_URL}/api/sendEmailTwofa/${this.uid}`, {
              headers: { accept: "application/json" },
            })
            .then((resp) => {
              // try {
              // } catch (err) {
              // }
            });
          }
          else
          {
            if (resp.data[0]["2fa"] == null) {
              this.generateQrCode();
              this.twofa = true
              this.twofactor = true;
            } else {
              this.twofa = false
              this.twofactor = true;
            }
          }
          this.$root.isLoading = false;
        } catch (err) {
          this.error = true;
          console.log("empty profile");
          this.$root.isLoading = false;
        }
      });
    },
    twofactorSubmit(){
      if(!this.form['2fa_email_code']){
        this.twofactorError = true;
        this.twofactorErrorMsg = 'Please enter 2-FA code.'
        return false;
      }
      else {
        if(window.navigator.onLine) {
          this.$root.isLoading = true;
          setTimeout(() => {
            this.$root.isLoading = false;
          }, 2000);
          axios.post(`${API_BASE_URL}/api/validateEmail2fa/${this.uid}`, {
            "2fa_email_code": this.form['2fa_email_code']
          })
          .then((response) => {
            this.loading = false;
            this.twofactorError=false;
            this.dialog2 = true;
            this.isEmail2fa = false;  
            this.form['2fa_email_code'] = '';
          })
          .catch(error  => {
            console.log('inisde catch');
            this.loading = false;
            if (error.response) {
              if(error.response.data.status == 0){
                this.twofactorErrorMsg = error.response.data.message;
                this.twofactorError = true;
              }else{
                this.error = error.response.data.message;
              }
            }
          });
        }
        else {
          this.twofactorError = true;
          this.twofactorErrorMsg = 'Please check your internet connectivity.';
          return false;
        }
      }
    },
    getOracleValue() {
      axios.get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
        headers: {
          accept: "application/json",
        },
      })
      .then((resp) => {
        //this.$root.isLoading = false;
        this.oracleprice = resp.data;
      });
    },
    SaveToken() {
      this.twofactor = false;
      this.dialog = true;
    },
    getuid() {
      this.getglobal();
    },
    generateQrCode() {
      this.$root.isLoading = true;
      axios
        .get(`${API_BASE_URL}/user/2fa/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.$root.isLoading = false;
          console.log(resp);
          this.authkey = resp.data;
        });
    },
    getglobal() {
      axios.get(`${API_BASE_URL}/user/info/${this.uid}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {
        try {
          this.investor = resp.data[0].investor;
          if (resp.data[0].investor == 0) {
            if (resp.data[0].signed == 1 || resp.data[0].signed == null) {
              this.signneed = false;
            } else {
              //this.signneed=true  //we are moving this functionality to shopify
            }
          }
          this.commission = resp.data[0].commission;
          this.first30days = resp.data[0].FirstDays;
            // if (resp.data[0]["2fa"] == null) {
            //   this.generateQrCode();
            // }
            //this.$root.isLoading = false;
        } catch (err) {
          this.error = true;
          console.log("empty profile");
          //this.$root.isLoading = false;
        }
      });
    },
    convertToDollar(reward, type){
      if(type == 'HNT'){
        return (reward * this.hntPrice).toFixed(2);
      }else if(type == 'HMT'){
        return (reward * this.hmtPrice).toFixed(2);
      }else if(type == 'IOT'){
        return (reward * this.iotPrice).toFixed(2);
      }
    },
    getRequestOld() {
      this.pendingTransactionsTotal = 0;
      this.alreadypayed = 0;
      axios.get(`${API_BASE_URL}/user/request/${this.uid}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {
        this.requestLog = resp.data;
        for (let i = 0; i < this.requestLog.length; i++) 
        {
          if (this.requestLog[i].payment == "HNT") 
          {

              this.requestLog[i].payment = this.requestLog[i].payment;
              this.requestLog[i].HNTchange = this.requestLog[i].amount;
        
            if (this.requestLog[i].flag == 0) 
            {
              this.requestLog[i].flag = "Pending";
              this.pendingHNT += parseFloat(this.requestLog[i].amount);
            } 
            else if (this.requestLog[i].flag == 1) 
            {
              this.requestLog[i].flag = "Completed";
              this.totalHNTPaid += parseFloat(this.requestLog[i].amount);
              // this.alreadypayed += parseFloat(this.requestLog[i].HNTchange);
            } 
            else if (this.requestLog[i].flag == 2) 
            {
              this.requestLog[i].flag = "Rejected";
            } 
            else if (this.requestLog[i].flag == 3) 
            {
              this.requestLog[i].flag = "Approved";
              this.totalHNTPaid += parseFloat(this.requestLog[i].amount);
            }
            this.requestLog[i].HNTchange = this.requestLog[i].HNTchange+ " HNT";
            this.requestLog[i].entered = this.changeFormat(this.requestLog[i].entered);
          }

          if (this.requestLog[i].payment == "HMT") 
          {
              this.requestLog[i].payment = this.requestLog[i].payment;
        
            if (this.requestLog[i].flag == 0) 
            {
              this.requestLog[i].flag = "Pending";
              this.pendingHMT += parseFloat(this.requestLog[i].amount);
            } 
            else if (this.requestLog[i].flag == 1) 
            {
              this.requestLog[i].flag = "Completed";
              //this.alreadypayed -= parseFloat(this.requestLog[i].HMTchange);
              this.totalHMTPaid += parseFloat(this.requestLog[i].amount);
            } 
            else if (this.requestLog[i].flag == 2) 
            {
              this.requestLog[i].flag = "Rejected";
            } 
            else if (this.requestLog[i].flag == 3) 
            {
              this.requestLog[i].flag = "Approved";
              this.totalHMTPaid += parseFloat(this.requestLog[i].amount);
            }
            this.requestLog[i].HMTchange = this.requestLog[i].HMTchange+ " HMT";
            this.requestLog[i].entered = this.changeFormat(this.requestLog[i].entered);
          }

          if (this.requestLog[i].payment == "IOT") 
          {
              this.requestLog[i].payment = this.requestLog[i].payment;
            
            if (this.requestLog[i].flag == 0) 
            {
              this.requestLog[i].flag = "Pending";
              this.pendingIOT += parseFloat(this.requestLog[i].amount);
            } 
            else if (this.requestLog[i].flag == 1) 
            {
              this.requestLog[i].flag = "Completed";
              //this.alreadypayed -= parseFloat(this.requestLog[i].IOTchange);
              this.totalIOTPaid += parseFloat(this.requestLog[i].amount);
            } 
            else if (this.requestLog[i].flag == 2) 
            {
              this.requestLog[i].flag = "Rejected";
            } 
            else if (this.requestLog[i].flag == 3) 
            {
              this.requestLog[i].flag = "Approved";
              this.totalIOTPaid += parseFloat(this.requestLog[i].amount);
            }
            this.requestLog[i].IOTchange = this.requestLog[i].IOTchange+ " IOT";
            this.requestLog[i].entered = this.changeFormat(this.requestLog[i].entered);
          }


        }

        console.log('final request log********', this.requestLog);

        this.getUserHotspots();

        //get last trans date for request payement button
        // var transLen = this.requestLog.length;
        // this.lastLastPaymentDate = this.requestLog
        //     ? this.requestLog[transLen - 1].entered.substring(0, 10)
        //     : "";
        // var today = new Date();
        // var lastDate = new Date(this.lastLastPaymentDate);
        // var diffTime = Math.abs(today - lastDate);
        // var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // console.log(diffDays + " days");
        // //allow request payment if last trans diff is 30 days
        // if (diffDays >= 30) {
        //   this.requestPaymentButton = true;
        // }
        //end
      });
    },
    sendRequest() {
      console.log('this.hntPrice', this.hntPrice);
      console.log('this.hmtPrice', this.hmtPrice);
      console.log('this.iotPrice', this.iotPrice);


      this.sucessAlert = false;
      this.alert = false;
      this.errorMsg = "";
      let errorFound = false;

      let selectedAmount = parseFloat(this.amount);

      if(this.message == 'HNT'){
        if (this.amount < 2) {
          this.alert = true;
          this.errorMsg =  this.dynamicMessage.hnt_amount_greater_than;
          return false;
        }

        console.log('this.remainHNTWithdrawal', this.remainHNTWithdrawal);
        console.log('selectedAmount', selectedAmount);


        if(selectedAmount > parseFloat(this.remainHNTWithdrawal)){
          console.log('inisde this block');
          this.alert = true;
          this.errorMsg = this.dynamicMessage.insufficient_amount_hnt;
          return false;
        }

      }

      if(this.message == 'IOT'){

        //check if iot value is equal to 2 HNT atleast
        let convertedDollarAmountIOT = selectedAmount * this.iotPrice;
        let dollarAmountOf2HNT = 2 * this.hntPrice;


        console.log('convertedDollarAmountIOT', convertedDollarAmountIOT);
        console.log('dollarAmountOf2HNT', dollarAmountOf2HNT);

        if (convertedDollarAmountIOT < dollarAmountOf2HNT) {
          this.alert = true;
          this.errorMsg = 'The value of entered IOT less than value of 2 HNT.';
          return false;
        } 

        if(selectedAmount > parseFloat(this.remainIOTWithdrawal)){
          console.log('inisde this block');
          this.alert = true;
          this.errorMsg = 'There is an insufficient amount of IOT to withdraw.';
          return false;
        }

      }

      if(this.message == 'HMT'){

        //check if hmt value is equal to 2 HNT atleast
        let convertedDollarAmountHMT = selectedAmount * this.hmtPrice;
        let dollarAmountOf2HNT = 2 * this.hntPrice;

        console.log('convertedDollarAmountHMT', convertedDollarAmountHMT);
        console.log('dollarAmountOf2HNT', dollarAmountOf2HNT);

        if (convertedDollarAmountHMT < dollarAmountOf2HNT) {
          this.alert = true;
          this.errorMsg = 'The value of entered HMT less than value of 2 HNT.';
          return false;
        } 

        console.log('this.remainHMTWithdrawalWithoutAbbr', this.remainHMTWithdrawalWithoutAbbr);
        if(selectedAmount > parseFloat(this.remainHMTWithdrawalWithoutAbbr)){
          console.log('inisde this block');
          this.alert = true;
          this.errorMsg = 'There is an insufficient amount of HMT to withdraw.';
          return false;
        }

      }


      //check if selected payment address has empty value
      if (this.message == "HNT" && !this.userInfo.HNT) {
        this.alert = true;
        errorFound = true;
      } else if (this.message == "BTC" && !this.userInfo.BTC) {
        this.alert = true;
        errorFound = true;
      } else if (this.message == "ETH" && !this.userInfo.ETH) {
        this.alert = true;
        errorFound = true;
      } else if (this.message == "PayPal" && !this.userInfo.paypal) {
        this.alert = true;
        errorFound = true;
      } else if (this.message == "Venmo" && !this.userInfo.venmo) {
        this.alert = true;
        errorFound = true;
      }
      else if (this.message == "HMT" && !this.userInfo.HMT) {
        this.alert = true;
        errorFound = true;
      }
      else if (this.message == "IOT" && !this.userInfo.IOT) {
        this.alert = true;
        errorFound = true;
      }

      if (errorFound) {
        this.errorMsg =
          this.dynamicMessage.selected_address_not_available;
        return false;
      }

      this.$root.isLoading = true;
      axios.post(`${API_BASE_URL}/user/request/${this.uid}`, {
        payment: this.message,
        amount: this.amount,
        HNTchanges: this.hntPrice,
        HMTchanges: this.hmtPrice,
        IOTchanges: this.iotPrice,
        flag: 0,
        totalMined: this.remainbalance
      }).then((res) => {
        if(res.data.status == '1') {
          this.sucessAlert = true;
        } else {
          this.errorMsg = this.dynamicMessage.insufficient_amount_hnt;
          this.alert = true;
          console.log("afq error message:"+this.errorMsg);
          console.log("afq alert:"+this.alert);
        }
       

        //close modal after two seconds and reset messasges
        setTimeout(() => {
          this.getuid();
          this.getRequestOld();
          this.dialog2 = false;
          this.sucessAlert = false;
          this.alert = false;
          this.errorMsg = "";
          this.amount = 0;
        }, 2000);
        this.$root.isLoading = false;
      }).catch(err => {
        console.log('something went wrong');
        this.$root.isLoading = false;
      })
     
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    closeRequest(){
      this.dialog2 = false;
    },
    getUserInfo() {
      axios.get(`${API_BASE_URL}/user/payment/${this.uid}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {
        try {
            this.investor = resp.data[0].investor;
            this.userInfo = resp.data[0];
            if(this.userInfo.HNT && /^.[a-zA-Z0-9]{50,50}$/.test(this.userInfo.HNT))
            {
              this.isHNTAddress = true;
            }else{
              
              this.isHNTAddress = false;
            }

            if(this.userInfo.IOT && /^.[a-zA-Z0-9]{50,50}$/.test(this.userInfo.IOT))
            {
              this.isIOTAddress = true;
            }else{
              
              this.isIOTAddress = false;
            }

            if(this.userInfo.HMT && /^.[a-zA-Z0-9]{50,50}$/.test(this.userInfo.HMT))
            {
              this.isHMTAddress = true;
            }else{
              
              this.isHMTAddress = false;
            }
        } catch (err) {
          console.log("empty profile");
        }
      });
    },
    changeFormat(date){
      return date?moment(date).format('MM-DD-YYYY, HH:mm:ss'):"";
    }, 
    getDynamicMessages() {
      axios
        .get(`${API_BASE_URL}/getMessageConfiguration/commisions`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.dynamicMessage = resp.data;
          } catch (err) {
            this.error = true;
          }
        });
    },
    getUserHotspots() {
      axios.get(`${API_BASE_URL}/user/hotspots/${this.uid}`, {
        headers: {
          accept: "application/json",
        },
      })
      .then((resp) => {
        let hotspots = resp.data;
        this.hotspotAll = hotspots;
        this.$root.isLoading = true;
        this.getHMTEarningsHotSpotty();
      })
      .catch((error) => {
        this.progressBar = false;
        this.$root.isLoading = false;
        console.log('error1', error);
      });
    },
    async getHMTEarningsHotSpotty(){
      let total = 0;
      let totalIot = 0;
      let totalHnt = 0;
      for(let i = 0; i < this.hotspotAll.length; i++){
        if(this.hotspotAll[i].hotspotType == '5G'){
          console.log('this.hotspotAll[i].hotspotType', this.hotspotAll[i].hotspotType);
          let res = await this.getHMTRewardHotSpotty(this.hotspotAll[i].Haddress, 'total');
          this.hotspotAll[i].total = res.mobileTotal;
          if(this.hotspotAll[i].total != 'N/A' && this.hotspotAll[i].total != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].total;
            console.log('hostCommission', hostCommission);
            total += parseFloat(hostCommission);
            this.hotspotAll[i].total = this.abbrNum(hostCommission, 3);
          }
        }
        else if(this.hotspotAll[i].hotspotType == 'IoT'){
          console.log('this.hotspotAll[i].hotspotType', this.hotspotAll[i].hotspotType);
          let res = await this.getHMTRewardHotSpotty(this.hotspotAll[i].Haddress, 'total');
          this.hotspotAll[i].total = res.iotTotal;
          if(this.hotspotAll[i].total != 'N/A' && this.hotspotAll[i].total != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].total;
            console.log('hostCommission', hostCommission);
            totalIot += parseFloat(hostCommission);
            this.hotspotAll[i].total = hostCommission;
          }
        }else if(this.hotspotAll[i].hotspotType == 'HNT'){
           console.log('this.hotspotAll[i].hotspotType', this.hotspotAll[i].hotspotType);
          let res = await this.getHMTRewardHotSpotty(this.hotspotAll[i].Haddress, 'total');
          this.hotspotAll[i].total = res.hntTotal;
          if(this.hotspotAll[i].total != 'N/A' && this.hotspotAll[i].total != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].total;
            console.log('hostCommission', hostCommission);
            totalHnt += parseFloat(hostCommission);
            this.hotspotAll[i].total = hostCommission;
          }
        }
      }


      console.log('totalHMT', total);
      console.log('totalHnt', totalHnt);
      console.log('totalIot', totalIot);

      this.remainHNTWithdrawal = (totalHnt - (this.pendingHNT + this.totalHNTPaid)).toFixed(2);
      this.remainIOTWithdrawal = (totalIot - (this.pendingIOT + this.totalIOTPaid)).toFixed(2);
      this.remainHMTWithdrawal = this.abbrNum(total - (this.pendingHMT + this.totalHMTPaid), 3);
      this.remainHMTWithdrawalWithoutAbbr = total - (this.pendingHMT + this.totalHMTPaid);
      
      this.totalHMTMinedWithoutAbbr = total;
      this.totalHMTMined = this.abbrNum(total, 3);
      this.totalIOTMined = totalIot.toFixed(2);
      this.totalHNTMined = totalHnt.toFixed(2);

      this.totalHNTMinedDollar = this.convertToDollar(totalHnt, 'HNT');
      this.totalHMTMinedDollar = this.convertToDollar(total, 'HMT');
      this.totalIOTMinedDollar = this.convertToDollar(totalIot, 'IOT');

      this.remainHNTWithdrawalDollar = this.convertToDollar(this.remainHNTWithdrawal, 'HNT');
      this.remainHMTWithdrawalDollar = this.convertToDollar(this.remainHMTWithdrawalWithoutAbbr, 'HMT');
      this.remainIOTWithdrawalDollar = this.convertToDollar(this.remainIOTWithdrawal, 'IOT');

      this.pendingHNTDollar = this.convertToDollar(this.pendingHNT, 'HNT');
      this.pendingHMTDollar = this.convertToDollar(this.pendingHMT, 'HMT');
      this.pendingIOTDollar = this.convertToDollar(this.pendingIOT, 'IOT');

      this.totalHNTPaidDollar = this.convertToDollar(this.totalHNTPaid, 'HNT');
      this.totalHMTPaidDollar = this.convertToDollar(this.totalHMTPaid, 'HMT');
      this.totalIOTPaidDollar = this.convertToDollar(this.totalIOTPaid, 'IOT');

      this.$root.isLoading = false;
    },
    formatHotspotName(name){
      let newName = name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
          return letter.toUpperCase();
      });

      return newName;
    },
    async getHMTRewardHotSpotty(hotspotAddress, filter){
      console.log('inisde gethntreward', hotspotAddress, filter);
      let minDate= '';
      let maxDate = '';
      if(filter == 'today'){
        minDate= moment().unix();
        maxDate = moment().unix();
      }

      if(filter == 'week'){
        minDate= moment().subtract(6, 'days').unix();
        maxDate = moment().unix();
      }

      if(filter == 'month'){
        minDate= moment().subtract(29, 'days').unix();
        maxDate = moment().unix();
      }

      if(filter == 'total'){
        minDate= moment().subtract(5, 'years').unix();
        console.log('minDate', minDate);
        maxDate = moment().unix();
      }

      

      return new Promise (function( resolve, reject ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}/rewards?start=${minDate}&end=${maxDate}`, {
          headers: {
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          let mobile = res.data.data.rewards.mobile;
          let mobileTotal = 0.00;
          for(let i = 0; i < mobile.length; i++){
            if(mobile[i].total > 0){
              mobileTotal += mobile[i].total;
            }
          }

          let iot = res.data.data.rewards.iot;
          let iotTotal = 0.00;
          for(let i = 0; i < iot.length; i++){
            if(iot[i].total > 0){
              iotTotal += iot[i].total;
            }
          }

          let hnt = res.data.data.rewards.hnt;
          let hntTotal = 0.00;
          for(let i = 0; i < hnt.length; i++){
            if(hnt[i].total > 0){
              hntTotal += hnt[i].total;
            }
          }

          resolve({
            mobileTotal: mobileTotal != 0.00 ? mobileTotal : 'N/A',
            iotTotal : iotTotal != 0.00 ? iotTotal : 'N/A',
            hntTotal: hntTotal != 0.00 ? hntTotal : 'N/A'
          });
        })
        .catch((err) => {
          resolve({
            mobileTotal: 'Server error',
            iotTotal : 'Server error',
            hntTotal: 'Server error'
          });
        })
      })
    },
    abbrNum(number, decPlaces) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10,decPlaces);

    // Enumerate number abbreviations
    var abbrev = [ "K", "M", "B", "T" ];

    // Go through the array backwards, so we do the largest first
    for (var i=abbrev.length-1; i>=0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10,(i+1)*3);

        // If the number is bigger or equal do the abbreviation
        if(size <= number) {
             // Here, we multiply by decPlaces, round, and then divide by decPlaces.
             // This gives us nice rounding to a particular decimal place.
             number = Math.round(number*decPlaces/size)/decPlaces;

             // Handle special case where we round up to the next abbreviation
             if((number == 1000) && (i < abbrev.length - 1)) {
                 number = 1;
                 i++;
             }

             // Add the letter for the abbreviation
             number += abbrev[i];

             // We are done... stop
             break;
        }
    }

    return number;
  }
  },
};
</script>
<style scoped>

.v-list-item__title{
  font-size:14px !important;
}

.qrcode {
  display: inline-block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 15%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
}


.v-btn.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;

}

.media {
  padding: 0;
  flex-direction: row;
  display: flex;
}

.vertical-line{
  display: inline-block;
  border-left: 1px solid #ddd;
  margin-right: 30px;
  height: 60px;
}
</style>

