<template>
    <v-alert type="info">
        Attention JAG hosts, 
        as of 2/15/2022 JAG will only be paying out in Helium’s native cryptocurrency, HNT.  
        Earnings will remain the same cash equivalent as before and are available to sell or trade the HNT for USD or other cryptocurrencies on one of the various exchanges that host HNT.  
        The following links will take you to a valid exchange which accepts HNT.  
        <a href="https://accounts.binance.us/en/register?ref=54420062" target="_blank">Create a free account | Binance.US</a> | <a href="https://crypto.com/" target="_blank">Crypto.com</a>.
    </v-alert>
</template>

<script>
export default {
    name: "message-banner",
    data: () => {
        return {
        };
    },
};
</script> 