<template>
  <section class="login-page">

    <Loader v-if="loader"></Loader>

    <div class="left-log">
      <div class="vh-cent">
        <div class="log-logo">
          <a target="New" href="https://jag.network"
            ><img src="@/assets/img/logo.png" alt="SITE-LOGO"
          /></a>
        </div>
        <h2>Welcome to JAG.</h2>
      
        <v-form ref="form" @submit="login">
          <v-alert
            dense
            outlined
            color="error"
            class="my-5"
            dismissible
            v-if="error"
          >
              {{error}}
          </v-alert>

          <v-alert
            dense
            outlined
            color="success"
            class="my-5"
            dismissible
            v-if="success2"
          >
              {{success2}}
          </v-alert>

   

          <div class="form-group">
            <label for="">Email address*</label>
            <v-text-field
              type="email"
              class="form-control"
              placeholder="Email"
              required
              v-model="form.email"
              :rules="rules.emailRules"
              
            >
            </v-text-field>

          </div>
          <div class="form-group">
            <label for="" class="clearfix">
              Password*
             
            </label>
            <v-text-field
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="form.password"
              required
              :rules="rules.requiredRules"
            >
            </v-text-field>                  
                                  
          </div>
           <span class="pull-right forgot-dev-pass">
                 Have account? please<v-btn :to="{name:'login'}"><u> Login</u></v-btn>
              </span>
          
          <button
            class="log-btn btn"
            type="button"
           @click="register"
          >
            Register
          </button>
        </v-form>
      </div>
    </div>


    <div class="right-log">
      <h1>Jag Host Portal</h1>
      <h1>
        <span
          >Register and see your tracked earnings, check hotspot status and request
          payouts.</span
        >
      </h1>
      <h3>Best Network Portal</h3>
    </div>


    <v-dialog
        v-model="twofactorselection"
        max-width="300px"
      >
        <v-card>

          <v-card-title class="headline purple darken-1 white--text">
            SELECT 2FA OPTION
          </v-card-title>
        
          <v-card-text>
            <v-radio-group
              v-model="twofactoroption"
              column
            >
              <v-radio
                label="Email"
                value="Email"
              ></v-radio>
              <v-radio
                label="Google Authenticator"
                value="Google Authenticator"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
         
          <v-divider></v-divider>
         
          <v-card-actions>
            <button
            class="log-btn btn default"
            type="button"
            @click="save2faOption"
          >
            Save
          </button>

          <button
            class="log-btn btn error"
            type="button"
            @click="twofactorselection = false"
          >
            Close
          </button>

            
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog persistent width="500" v-model="twofactor">
      <v-card>

        <v-card-title class="headline purple darken-1 white--text">
          Two Factor Autentication is Needed
        </v-card-title>

        <!-- 2fa using email -->
        <v-card-text class="pa-3 2fa_email_code_email" v-if="twofactoroption=='Email'">
          <div>
            <span>
              Please check your email for Two-factor authentication..
            </span>
            <div>
              <div align="center" justify="center">
                <template>
                   <v-text-field
                      v-model="form['2fa_email_code']"
                      label="2FA"
                      type="text"
                    ></v-text-field>
                </template>
              </div>

              <div>
                <v-btn color="success" @click="twofactorSubmit()">Submit 
                   <v-progress-circular
                      v-if="loading"
                      :size="20"
                      :width="2"
                      color="white"
                      indeterminate
                    ></v-progress-circular>
                </v-btn>
              </div>

              <div v-if="twofactorError" align="center" justify="center" style="margin-top: 24px">
                <v-alert type="error" transition="fade-transition">
                  {{twofactorErrorMsg}}
                </v-alert>
              </div>
              <div v-if="online" align="center" justify="center" style="margin-top: 24px">
                <v-alert type="error">
                   Please check your internet connection and try again.
                </v-alert>
              </div>

            </div>
          </div>
        </v-card-text>

       
        <!-- 2fa using google auth -->
        <v-card-text class="pa-3 2fa_email_code_google" v-if="twofactoroption=='Google Authenticator'">
          <div>
            <span v-if="twofa">
              Scan this QR code with Google Autenticator and do not
              delete the app. If you remove that you will not be able to
              restore your code. Once you already have the Google
              Authenticator code. Then enter the code provided from the
              app.
            </span>
             <span v-if="!twofa">
              Please enter the sign-in 2FA code from your authenticator app.  
              If having issues, then reach out to <strong>support@jagindustrials.com</strong>.
            </span>
            <div>
              <v-row v-if="twofa" align="center" justify="center">
                <template>
                  <figure>
                    <vue-qrcode
                      :value="this.authkey"
                      :options="{
                        errorCorrectionLevel: 'Q',
                        width: 200,
                        height: 200,
                      }"
                    ></vue-qrcode>
                  </figure>
                </template>
                <v-btn icon color="green" @click="getNewQR()">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </v-row>
              <v-row align="center" justify="center">
                <span>
                  Please enter the OTP here to check your settings
                </span>
              </v-row>
              <v-row align="center" justify="center">
                <CodeInput
                  :key="componentKey"
                  :loading="false"
                  :autoFocus="true"
                  class="input"
                  v-on:change="onChange"
                  v-on:complete="onComplete"
                />
              </v-row>
              <br /><br />
              <v-alert type="success" v-model="correcttoken"
                >The Generated Token is Correct</v-alert
              >
              <v-alert type="error" v-model="errortoken"
                >The Generated Token is Incorrect</v-alert
              ><br />

              <v-alert type="error" v-model="online"
                >Please check your internet connection and try again.</v-alert
              ><br />
              
            </div>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>

  </section>
</template>

<script>
    import firebase from "firebase";
    import {mapGetters} from "vuex";
    import axios from "axios";
    import { API_BASE_URL } from "../../constants";
    import Loader from '../../components/loader';
    import './style.css';
    

    const API_URL = `${API_BASE_URL}/api/auth/`;
    var dynamicMessage = {
      email: "",
      password: "",
    };
    export default {
        name: 'App',
        components: { Loader },
        data: () => ({
            form: {
                email: "",
                password: "",
            },
            twofactor: false,
            twofactorError: false,
            twofactorErrorMsg: '',
            twofactorselection:false,
            twofa:false,
            error: null,
            success2: null,
            uid: '',
            User:"",
            rememberMe:false,
            loader:true,
            loading:false,
            twofactoroption:"Email",
            correcttoken: false,
            errortoken: false,
            online:false,
            componentKey: 0,
            authkey: "",
            "2fa_type":0,
            investor:1,
            googleTwoValue: '',
            rules: {
                requiredRules: [v => !!v || dynamicMessage.password],
                emailRules: [
                    v => !!v || dynamicMessage.email,
                    v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) || 'E-mail must be valid',
                ],
            }
        }),
        computed: {
            
            ...mapGetters({
                user: "user"
            })
            
        },
        mounted() {
          if(this.$route.query.userId)
          {
            this.loginUsingFirebaseId(this.$route.query.userId);
          }
          console.log("after mount",this.rememberMe); 
          this.checkRememberMe();
          this.getDynamicMessages();
          //hide page loader  
          setTimeout(() => {
            this.loader = false;
          }, 2000)
        },
        methods: {
           register() {
                this.error = null;
                if (this.$refs.form.validate()) {
                    firebase
                        .auth()
                        .createUserWithEmailAndPassword(this.form.email, this.form.password)
                        .then(data => {
                            data.user
                                .updateProfile({
                                    displayName: this.form.email
                                })
                                .then(() => {
                                });
                    const user = firebase.auth().currentUser;
                    console.log(user)
                  axios.post(`${API_BASE_URL}/user/${user.uid}`,{email:this.form.email,name:this.form.email})
                  .then((response) => {
  axios.post(`${API_BASE_URL}/wallet/${user.uid}`,{address:"13XUm3U9NkQzdiH28YH5hsXd27b83cLGLaFf4PiCt4FxW3dFJXm"});
                  axios.post(`${API_BASE_URL}/payment/${user.uid}`,{});  
}, (error) => {
  console.log(error);
});                               
                  // send the signed in user a verification email
          this.success2 = "Thank you for signing up with JAG. A registration confirmation email has been sent to your account. Please complete the registration  process by following instructions in the email."
                  user.sendEmailVerification();
                        })

                        .catch(err => {
                            this.error = err.message;
                        });
                }
            },
          login(type) {
              this.error=null;
              this.isRememberMe();

              if (this.$refs.form.validate()) {
                  this.loading = true;

                  firebase
                  .auth()
                  .signInWithEmailAndPassword(this.form.email, this.form.password)
                  .then((data) => {

                      this.uid = data.user.uid;
                      this.User = data.user;

                      //console.log("data.user = ",data.user)

                      //handle 2fa
                      this.get2faOption();

                  })
                  .catch(error => {
                      if(error?.code === "auth/user-not-found" || error?.code === "auth/wrong-password") {
                        this.error = "Incorrect email or password";
                      }
                      else {
                        this.error = error.message;
                      }
                  });
              }
          },
          setcookie(){
            this.$cookies.set('uid', this.user.data.uid)
          },
          checkRememberMe(){

            if (localStorage.checkbox && localStorage.checkbox !== "") {
              this.rememberMe = true;
              this.form.email = localStorage.username;
            } else {
              this.rememberMe = false;
              this.form.email = "";
            }
          },
          isRememberMe(){
            if (this.rememberMe && this.form.email !== "") {
              localStorage.username = this.form.email;
              localStorage.checkbox = this.rememberMe;
            } else {
              localStorage.username = "";
              localStorage.checkbox = "";
            }
          },
          save2faOption(){

            if(this.twofactoroption == 'Email'){
              this['2fa_type'] = 1;
            }else{
              this['2fa_type'] = 2;
            }

          axios
          .put(`${API_BASE_URL}/user/update2faType/${this.uid}`, {
              "2fa_type": this['2fa_type']
            })
            .then((resp) => {
               this.handle2faoption();
            }).catch((resp) => {
              console.log("resprr = ",resp)
            });

          },
          get2faOption(){

            axios.get(`${API_BASE_URL}/user/info/${this.uid}`,{
                headers: { accept: "application/json" },
              })
              .then((resp) => {
                try {
                   
                 this['2fa_type'] = resp.data[0]['2fa_type'];
                 this.investor = resp.data[0].investor;
                 this.googleTwoValue =  resp.data[0]['2fa'];
                 //handle 2fa option as per 2fa_type
                 this.handle2faoption();

                } catch (err) {
                    this.error = "please try again.";
                }

              });

          },
          handle2faoption(){

             if(this['2fa_type'] == 1){
                  this.twofactorselection = false;
                  this.twofactor = true;

                  this.twofactoroption = 'Email';
                  this.validateTwoFactorAuth();
              }
              else if(this['2fa_type'] == 2){
                  this.twofactorselection = false;
                  this.twofactor = true;
                  
                  this.twofactoroption = 'Google Authenticator';

                  if(!this.googleTwoValue){
                    this.twofa = true;
                    this.generateQrCode();
                  }
              }else{
                  this.twofactorselection = false;
                  this.twofactor = false;
                  this.twofactorselection = true;

                  /*if(!this.twofactor){
                      this.twofactorselection = true;
                  }else{
                     if(this.twofactoroption == 'Email'){
                        this.validateTwoFactorAuth();
                     }
                  }*/
              }
          },
          twofactorSubmit(){

            if(window.navigator.onLine){

                this.online = false;

                if(!this.form['2fa_email_code']){
                  this.twofactorError = true;
                  this.twofactorErrorMsg = 'Please enter 2-FA code.'
                  return false;
                }
                this.validateTwoFactorAuth();

            }else{
                this.online = true;
                setTimeout(()=>{this.twofactor = false;this.online = false;},5000)
            }

          },
          validateTwoFactorAuth(){

            this.error = null;
            axios
              .post(API_URL + "validate2fa", {
                username: this.form.email,
                "2fa_email_code": this.form['2fa_email_code']
              })
              .then((response) => {

                
                this.loading = false;
                if(response.data.status == 1){
                  this.twofactor = true;
                  return false;
                }else {

                 //store user data into local storage when login into system completely
                  localStorage.setItem('User', JSON.stringify({"uid":this.User.uid,"email":this.User.email,"displayName":this.User.displayName}));

                  console.log("complete successfull login")

                  //redirect to page after successfull login
                  this.redirectTo()
                  
                }
              })
              .catch(error  => {
                  console.log('inisde catch');
                  this.loading = false;
                  if (error.response) {
                      if(error.response.data.status == 0){
                        this.twofactorErrorMsg = error.response.data.message;
                        this.twofactorError = true;
                      }else{
                        this.error = error.response.data.message;
                      }
                  }

              });
          },
          validateTwoFactorAuthGoogle() {
            axios
              .get(`${API_BASE_URL}/user/info/${this.uid}`, {
                headers: { accept: "application/json" },
              })
              .then((resp) => {
                try {
                  this.email = resp.data[0].email;
                  console.log('resp.data[0]["2fa"]', resp.data[0]["2fa"]);
                  if (resp.data[0]["2fa"] == null) {
                    this.generateQrCode();
                    this.twofa = true
                    this.twofactor = true;
                    
                  } else {
                    this.twofa = false
                    this.twofactor = true;
                  }
                } catch (err) {
                  this.error = true;
                  console.log("empty profile");
                }
              });
          },
          redirectTo(){
            if(this.investor == 1){
                this.$router.replace({name: "investor"})
            }
            else{
                this.$router.replace({name: "hotspot"})
            }
          },
          forceRerender() {
            this.componentKey += 1;
          },
          getNewQR() {
            this.generateQrCode();
          },
          generateQrCode() {
            axios
              .get(`${API_BASE_URL}/user/2fa/${this.uid}`, {
                headers: {
                  accept: "application/json",
                },
              })
              .then((resp) => {
                console.log(resp);
                this.authkey = resp.data;
              });
          },
          onChange(v) {
            this.correcttoken = false;
            this.errortoken = false;
          },
          onComplete(v) {

            if(window.navigator.onLine){

                this.online = false;
                var data = {
                  token: v,
                };
                axios
                .post(`${API_BASE_URL}/user/token/${this.uid}`, data, {
                  headers: {
                    accept: "application/json",
                  },
                })
                .then((resp) => {
                  console.log(resp, data);

                  this.errortoken = false;
                  this.forceRerender();
                  if (resp.data == true) {console.log('here');
                    //store user data into local storage when login into system completely
                    localStorage.setItem('User', JSON.stringify({"uid":this.User.uid,"email":this.User.email,"displayName":this.User.displayName}));
                    this.correcttoken = true;
                    this.twofactor = false;
                    this.dialog2 = true;
                    this.correcttoken = false;
                    this.redirectTo();
                  } else {
                    this.errortoken = true;
                  }
                }).catch((resp) => {
                  console.log("resprr = ",resp)
                });
            }else{
              this.online = true;
              this.forceRerender();
                  
              setTimeout(()=>{this.twofactor = false;this.online = false;},5000)
            }

          },
    getDynamicMessages() {
      axios
        .get(`${API_BASE_URL}/getMessageConfiguration/login`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            dynamicMessage = resp.data;
          } catch (err) {
            this.error = true;
          }
        });
    },
    loginUsingFirebaseId(uid){

      axios.get(`${API_BASE_URL}/user/info/${uid}`,{
        headers: { accept: "application/json" },
              })
              .then((resp) => {
                try {
                  
                  this.investor = resp.data[0].investor; 
                  localStorage.setItem('User', JSON.stringify({"uid":uid,"email":resp.data[0].email, "displayName":resp.data[0].name}));
                 
                 this.$cookies.set('uid', uid)
                 this.redirectTo();

                } catch (err) {
                    this.error = "please try again.";
                }

              });
    }
            
    
          

        },
    };
</script>


