<template>
  <div class="main-view-sec">
    <Loader v-if="loader"></Loader>

    <div class="contact-page clearfix">
      <div class="left-contact-page">
        <div class="vh-center">
          <img src="@/assets/img/helpdesk.png" alt="Image" />
        </div>
      </div>
      <div class="right-contact-page">
        <v-form ref="form">
          <div class="vh-center">
            <h1>Submit Host Support Request</h1>
            <p>
              Please submit the form below for any questions about your DISH
              account or technical support regarding your hotspot and a DISH team
              member will be in touch as soon as possible.
            </p>

            <v-alert dense outlined type="error" v-if="error">
              {{ error }}
            </v-alert>

            <v-alert dense text type="success" v-if="success">
              {{ success }}
            </v-alert>

            <div class="row">
              <div class="col-sm-6">
                <v-text-field
                  v-model="form.name"
                  label="Name*"
                  class="form-control"
                  :rules="rules.nameRules"
                >
                </v-text-field>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="form.email"
                  label="Email Id*"
                  type="email"
                  class="form-control"
                  :rules="rules.emailRules"
                >
                </v-text-field>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="form.phone"
                  label="Phone*"
                  class="form-control"
                  :rules="rules.phoneRules"
                >
                </v-text-field>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="form.subject"
                  label="Subject*"
                  class="form-control"
                  :rules="rules.subjectRules"
                >
                </v-text-field>
              </div>
              <div class="col-sm-12">
                <v-textarea
                  v-model="form.message"
                  label="Message*"
                  auto-grow
                  rows="1"
                  type="text"
                  row-height="15"
                  autocomplete="off"
                  :rules="rules.messageRules"
                  class="form-control"
                >
                </v-textarea>
              </div>
            </div>
            <div class="text-center">
              <v-btn class="theme-btn" @click="send"
                >send

                <v-progress-circular
                  v-if="loading"
                  :size="20"
                  :width="2"
                  color="white"
                  indeterminate
                ></v-progress-circular>
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>

      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import { API_BASE_URL } from "../../../constants";
import Loader from "../../../components/loader";
import "./index.css";

var dynamicMessage = {
  required_field: "",
  success_message: "",
  email_valid: "",
  phone_valid: "",
};
export default {
  components: { Loader },
  setup() {},
  data() {
    return {
      loader: true,
      loading: false,
      form: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      thankYouMessage: "",
      error: null,
      success: null,
      rules: {
        requiredRules: [(v) => !!v || dynamicMessage.required_field],
        nameRules: [],
        subjectRules: [],
        messageRules: [],
        emailRules: [],
        phoneRules: [],
      },
      token: {
        accessToken: "",
      },
    };
  },

  beforeMount() {
    //hide page loader
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  },
  mounted() {
    this.getDynamicMessages();
  },
  methods: {
    send() {
      this.error = null;

      //enabled validation only on send button and removed from indivisual field
      this.rules.nameRules = [
        (v) => !!v || "Name is required",
        (v) => !!/^[a-z\s]+$/i.test(v) || "Name must be valid",
      ];
      this.rules.subjectRules = [
        (v) => !!v || "Subject is required",
        (v) => !!/^[a-z\s]+$/i.test(v) || "Subject must be valid",
      ];
      this.rules.messageRules = [
        (v) => !!v || "Message is required",
        (v) =>
          !!/^[a-zA-Z0-9\s?!@#\$%\^\&*\)\(+=._-]+$/g.test(v) ||
          "Message must be valid",
        //v => !!/^[a-z\s]+$/i.test(v) || 'Message must be valid',
      ];
      this.rules.emailRules = [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
          "E-mail must be valid",
      ];
      this.rules.phoneRules = [
        (v) => !!v || "Phone is required",
        (v) =>
          !!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/im.test(v) ||
          "Phone must be valid",
      ];
      //end

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.loading = true;

          axios
            .post(`${API_BASE_URL}/contactus`, {
              name: this.form.name,
              email: this.form.email,
              phone: this.form.phone,
              subject: this.form.subject,
              message: this.form.message,
            })
            .then(
              (response) => {
                this.success = this.thankYouMessage;
                this.$refs.form.reset();
                this.loading = false;
                //auto hide after 5 sec
                setTimeout(() => (this.success = null), 5000);
              },
              (error) => {
                this.error = error.message;

                //auto hide after 5 sec
                setTimeout(() => (this.error = null), 5000);
              }
            );
        }
      });
    },
    getDynamicMessages() {
      axios
        .get(`${API_BASE_URL}/getMessageConfiguration/contactus`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            dynamicMessage = resp.data;
            this.thankYouMessage = resp.data.success_message;
          } catch (err) {
            this.error = true;
          }
        });
    },
  },
};
</script>
<style scoped>
.right-contact-page .form-control {
  border: none;
  background: none;
}
</style>
